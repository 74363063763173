.alert{
    width: 500px;
    text-align: center;
    margin: auto;
    position: sticky;
    top: 20px;
    background-color: red;
    text-transform: uppercase;
    color: antiquewhite;
    display: none;
}