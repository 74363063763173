.row {
    width: 100%;
    display: flex;
    background-color: black;
    justify-content: space-evenly;
    color: white;
    font-size: 1rem;
  }

  .low-text{
    font-size: 0.75rem;
  }

  
  @media only screen and (max-width: 650px) {
    .row{
      font-size: 0.75rem;
    }
    .low-text{
      font-size: 0.5rem;
    }
  }