.details-container{
    background-color: #f2fad3;
    margin-top: 10px;
}

.main-container{
    min-height: 99vh;
    position: relative;
    margin: auto;
    max-width: 1200px;
  }

.map-container{
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    justify-content: center;
}

.youtube-container{
    width: 100%;
    margin-top: 5px;
    text-align: center;
}

#thumbnail-div{
    justify-content: center;
}

.map-container iframe{
    height: 400px;
    margin: 2px;
}

.map-location{
    width: 40%;
}
.map-streetview{
    width: 59%;
}

.youtube iframe{
    margin-top: 25px;
    height: 400px;
    width: 100%;
    max-width: 900px;
    border-radius: 5px;
}

@media only screen and (max-width: 750px) {
    .map-container iframe{
        width: 100%;
        height: 70vw;
    }
    .youtube-container iframe{
        width: 100%;
        height: 70vw;
        margin-top: 0;
    }
  }