.input{
    display: block;
    margin: auto;
    width: 80%;
}
.button{
    display: block;
    margin: auto;
}

.label{
   display: block;
   text-align: center;
}
option{
    text-transform: capitalize;
}