.card {
    position: relative;
    font-family: 'Fredoka One', cursive;
    height: 250px;
    width: 250px;
    display: inline-block;
    /* border-radius: 5px; */
    transition: all 0.2s;
    margin: 30px 1% 0 1%;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
  }
  .card:hover {
    transform: translateY(-3px) scale(1.05);
    border: 2px solid rgba(163, 163, 163, 0.563);
  }

.button-container{
    display: flex
}

.img-card{
    width: 100%;
    height: 70%
}

.form{
    padding: 5px;
    display: none;
    transition: all 0.5s;
}